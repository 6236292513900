<template>
  <div class="my-container">
    <!--  头像  -->
    <div 
			v-if="isDaDiSign"
			class="sign-up"
			@click="!is_signed && signUpDialog()"
		>
			{{is_signed ? '已签到' : '签到'}}
		</div>
		
    <div class="head-box df fdr alc jc-sb">
      <div class="df alc">
        <img @click="toMyInfo" :src="head_img" alt="head_img" class="head-img" />
        <div v-if="type !== 2" @click="toMyInfo" class="my-sty">
					{{nickname}}
				</div>
        <div v-else class="info-box df fdc als jc-sb my-sty">
          <div @click="toMyInfo">{{use_info.nickname}}</div>
        </div>
      </div>
      <div @click="toMyInfo" v-if="!customAccount('VUE_APP_CHAOWEI') " class="detail_box df alc jc-end">
        <div class="detail_icon" />
      </div>
    </div>
    <div v-if="p_nickname" class="p_nickname">
			推广人：{{p_nickname}}
		</div>
		
    <!-- 除内采商城不需要余额和充值 -->
    <div
			v-if="type !== 3 && !icbcStatus && !tripartite('VUE_APP_TABEN') && fk_show && zgyh_show"
			class="newly"
		>
			<div 
				v-if="
					isKunshan || 
					tripartite('VUE_APP_BDT') ||
					tripartite_type === 'VUE_APP_ZJG'
				"
				class="newlyDv"
			>
				<div class="balance">
					<div class="balanceImg"></div>
					<div class="balanceFigure">积分</div>
				</div>
				<div class="integral kunshang">
					{{ type === 1 ? money : balanceInt }}
				</div>
			</div>

			<div v-else class="newlyDv">
				<!-- 如果是现金商城就跳转到我的账户 -->
				<router-link v-if="type === 1" to="account" class="balance">
					<div class="balanceImg" />
					<div class="balanceFigure">
						&yen;{{ type === 1 ? money : balanceInt }}
					</div>
				</router-link>
				<!-- 如果是现金商城就跳转到我的账户 -->
				
				<router-link v-if="isDaDiSign" to="integral_list_sign" class="balance">
					<div class="balanceImg" />
					<div class="balanceFigure">
						&yen;{{ type === 1 ? money : balanceInt }}
					</div>
				</router-link>
				
				<!-- 如果是积分商城就跳转到我的积分 -->
				<router-link
					v-if="type === 2 && !isDaDiSign"
					:class="!customAccount('VUE_APP_CHAOWEI')?'balance':'balanceChaoWei'"
					to="integral_list"
				>
					<div :class="type == 2 ? 'jifenzs' :'balanceImg'" />
					<div class="balanceFigure">
						{{ type === 1 ? money : balanceInt }}
					</div>
				</router-link>
				<!-- 如果是积分商城就跳转到我的积分 -->
				
				<router-link class="integral" to="/exchange" v-if="!customAccount('VUE_APP_CHAOWEI') && jy_show">
					<!-- <div class="integralImg"></div> -->
          <div :class="type == 2 ? 'jifen' :'balanceImg'" />
					<div class="integralFigure">兑换</div>
				</router-link>
				
        <router-link
					v-if="(type == 1 || type == 2) && !customAccounttype && jy_show"
					class="integral presented" to="/presented"
				>
					<!-- <div class="balanceImg"></div> -->
          <div :class="type == 2 ? 'jifenzs' :'balanceImg'" />
					<div class="integralFigure">赠送</div>
				</router-link>
			</div>
    </div>

    <!-- 订单  -->
    <div class="order-box">
      <div @click="orderList('ALL')" class="order-title df fdr alc jc-sb">
        <div class="left">我的订单</div>
        <div class="right">查看全部订单</div>
      </div>
      <div class="order-type df fdr alc jc-sb">
        <div
          class="order-item df fdc alc jc-sb"
          v-for="(item, index) in orderIcon"
          :key="index"
          @click="orderList(item.key)"
        >
          <icon-svg class="main-color" :icon-class="item.icon_class" />
          <div v-if="item.title == '待付款' && type == 3">待审批</div>
          <div v-else>{{item.title}}</div>
          <div v-if="item.number > 99" class="mark" style="width: 0.4rem">99+</div>
          <div v-else-if="item.number > 0" class="mark">{{item.number}}</div>
        </div>
      </div>
    </div>
		<!-- 订单  -->
		
    <!--  tab-list  -->
    <div class="tab-list df fdc alc">
      <router-link
        v-if="type===3"
        :to="{name:'approval_list',query: {key:(use_info.user_type==1?3:1)}}"
        class="tab-item df fdr alc"
      >
        <img src="../../assets/icon_my_caigou@3x.png" alt />
        <div class="right-tab border-bottom-1px">采购审批</div>
      </router-link>
      <router-link
				v-if="type===1 && !icbcStatus && fk_show && zgyh_show"
				to="account"
				class="tab-item df fdr alc"
			>
        <img src="../../assets/icon_my_zhanghu@3x.png" alt />
        <div class="right-tab border-bottom-1px">我的账户</div>
      </router-link>
      <router-link to="address" class="tab-item df fdr alc">
        <img src="../../assets/icon_wd_dizhi@3x.png" alt />
        <div class="right-tab border-bottom-1px">收货地址</div>
      </router-link>
      <router-link to="collection" class="tab-item df fdr alc">
        <img src="../../assets/icon_my_shouchang@3x.png" alt />
        <div class="right-tab border-bottom-1px">我的收藏</div>
      </router-link>
      <router-link
				v-if="
					type !== 3  && !tripartite('VUE_APP_TABEN') &&
					fk_show && tripartite_type !== 'VUE_APP_ZJG' &&
          !customAccount('VUE_APP_CHAOWEI') && zgyh_show && jy_show
				"
				to="goods_reserve"
				class="tab-item df fdr alc"
			>
        <img src="@/static/image/wuzicangku.png" alt />
        <div class="right-tab border-bottom-1px">物资领用</div>
      </router-link>
      <router-link
				v-if="
					type !== 3 && !tripartite('VUE_APP_TABEN') &&
					fk_show && tripartite_type !== 'VUE_APP_ZJG' &&
          !customAccount('VUE_APP_CHAOWEI') && zgyh_show && jy_show
				"
				to="reserve_details"
				class="tab-item df fdr alc"
			>
        <img src="@/static/image/jilu.png" alt />
        <div class="right-tab border-bottom-1px">领用记录</div>
      </router-link>
      <!-- <router-link
				v-if="use_info.is_budget_officer && type === 3" to="approval"
				class="tab-item df fdr alc"
			>
        <img src="../../assets/icon_wd_audit@3x.png" alt />
        <div class="right-tab border-bottom-1px">预算审批</div>
      </router-link> -->
      <router-link to="coupons" class="tab-item df fdr alc" v-if="zgyh_show && jy_show">
        <img src="../../assets/icon_my_youhuiquan@3x.png" alt />
        <div class="right-tab border-bottom-1px">我的优惠券</div>
      </router-link>
      <router-link to="lucky_wheel" class="tab-item df fdr alc" v-if="zgyh_show && jy_show">
        <img src="../../assets/icon_my_zhuanpan@3x.png" alt />
        <div class="right-tab">幸运大转盘</div>
      </router-link>
    </div>
		<!--  tab-list  -->

    <div class="tab-list df fdc alc" >
      <div
				v-if="ServiceStatus==1"
				@click="openChat"
				class="tab-item df fdr alc"
			>
        <img src="~@/assets/icon_shangpin_kefu@2x.png" alt />
        <div class="right-tab border-bottom-1px">客服中心</div>
      </div>

      <!--todo 拨打电话-->
      <a v-if="ServiceStatus==0" class="tab-item df fdr alc" @click="openChat">
        <img src="../../assets/icon_my_kefu@3x.png" alt />
        <div class="right-tab border-bottom-1px">
          客服中心
        </div>
      </a>
      <div v-if="sermob_my==true" class="PopUp">
        <div class="bot-mobile" @click="openChat">
          <img src="~@/assets/ic_back_.png" @click.stop="cancel"/>
          <span class="service-phone">
            客服电话:
            <span>{{ServiceMobile}}</span>
          </span>
          <span class="dial">
            <a :href="'tel:' + ServiceMobile">确认呼叫</a>
          </span>
          <span class="cancel" @click.stop="cancel">取消</span>
        </div>
      </div>
      <router-link to="feedback" class="tab-item df fdr alc">
        <yd-icon
					name="feedback"
					size="0.48rem"
					color="#666"
					style="padding-left: 0.3rem"
				></yd-icon>
        <div class="right-tab feedbackStyle border-bottom-1px">意见反馈</div>
      </router-link>
      <router-link
				v-if="!icbcStatus && tripartite_type !== 'VUE_APP_ZJG'"
				to="setting"
				class="tab-item df fdr alc"
			>
        <img src="../../assets/icon_my_shezhi@3x.png" alt />
        <div class="right-tab">设置</div>
      </router-link>
    </div>
		
    <div v-if="!sermob_my" style="height: 1rem" />
		
    <!-- S 底部导航 -->
    <foot-nav></foot-nav>
    <!-- E 底部导航 -->

    <!-- 签到弹窗 -->
    <div
			v-show="toast_control"
			class="toast-mask"
			@click="toast_control=false"
		/>
		<div v-if="toast_control" class="toast">
			<div class="toast-container df fdc alc">
				<p class="success">恭喜签到成功</p>
				<p class="success-point">
					获得
					<span class="point-amount">{{asign_point}}</span>
					积分！
				</p>
				<div class="img">
					<img src="../../assets/sign.png" alt />
				</div>
				<p>
					您已连续签到
					<span class="point-amount">{{asign_days}}</span>
					天
				</p>
			</div>
		</div>

		<dialog-alert
			:textMyPage="'是否补签并完成连续签到？'"
			:textContent="'昨日未签到！'"
			:toastControl="toast_open"
			@confirmBtn="confirmBtn"
			@closeDialog="closeDialog"
		></dialog-alert>
  
		<!-- 补签成功提示 -->
		<div class="reissue-success">
			<div v-if="dialogClose" class="toast-mask" />
			<div v-if="dialogClose" class="toast">
				<div class="toast-containe">
					<p class="success-txt">恭喜补卡成功！</p>
					<p class="success-poin">
						补签{{this.success_txt[1]}}月{{this.success_txt[0]}}日获得
						<span>{{this.success_txt[2]}}</span>积分！
					</p>
				</div>
			</div>
		</div>
		<!-- 补签成功提示 -->
	</div>
</template>

<script>
import FootNav from "@/components/footer/FootNav.vue";
import { scrollerMixin } from "@/mixins/scrollerMixin";
import { getStore } from "@/utils/common";
import { tripartite, customAccount } from "@/utils/custom";
import { mapState } from "vuex";
import "@/utils/qiyu";
import { ServicePhone } from "@/services";
import  DialogAlert  from "@/components/dialog/dialog"

import {
  getOrderNumber,
  getServiceConfig,
  getUserAccountList,
  directSign,
  supplementalSign,
	getSystemUserInfo
} from "@/services/my";

import { baseUrl } from "../../utils/env";

export default {
  name: "my",
  components: {
    FootNav,
    DialogAlert
  },
  mixins: [scrollerMixin],
  data() {
    return {
      show_integral: false,
      orderIcon: [
        { title: "待付款", key: "PAY", icon_class: "yi_icon_my_fukuan" },
        { title: "待发货", key: "DELIVER", icon_class: "yi_icon_my_fahuo" },
        { title: "待收货", key: "TAKE", icon_class: "yi_icon_my_shouhuo" },
        { title: "待评价", key: "EVALUATE", icon_class: "yi_icon_my_pingjia" },
        {
          title: "退货/款",
          key: "AFTER_SALE",
          icon_class: "yi_icon_my_tuikuan"
        }
      ],
      number: [],
      service_mobile: "", //客服电话
      service_time: "", //客服电话时间
      page: 1, // 开始查询的下标
      listRows: 10, // 每次查询的数量
      money: 0.0,
			balanceInt: 0,
      list: [],
      current_tab: 1, //1全部，2收入，3支出
      tab_list: ["全部", "收入", "支出"],
      is_request: 0,
      ServiceMobile: "",
      ServiceStatus: "",
      sermob_my: false,
			icbcStatus: false,	//工行环境
      fk_show: true, //饭卡商城显隐控制
      isKunshan: false,	// 是否昆山商城
      isDaDiSign: false,  //  时候大地保险签到
      toast_control: false,  // 签到弹窗状态
      toast_open: false,  //  是否签到弹窗
      asign_point: 0,  //  签到积分
      asign_days: 0,  //  签到天数
      no_asign: false,  // 用户不点击补签时候而且签到
      dialogClose: false,  // 补签成功弹窗
      success_txt: [],
      // customAccount: false,  //  是否特殊商城登录
      zgyh_show:true,   //中行显隐控制
      customAccounttype: false,  //  是否特殊商城登录
      jy_show:true    //江阴显隐控制
    };
  },
  computed: {
    ...mapState({
      //头像
      head_img: state =>
        state.userInfo.heagurl
          ? state.userInfo.heagurl
          : require("../../assets/ic_head_default@3x.png"),
      //用户信息
      use_info: state => state.userInfo,
      nickname: state =>
        state.userInfo.nickname
          ? state.userInfo.nickname
          : state.userInfo.mobile,
      type: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      // type: state => 3
      bid: state => state.businessInfo.id,
      p_nickname: state => state.userInfo.p_nickname, //父级推广人昵称
      is_signed: state => state.userInfo.is_signed_today,  // 是否签到
			tripartite_type: state => state.tripartite_type // 三方商城类型
    })
  },
  activated() {
    sessionStorage.route = this.$route.name;
		this.getBalance();
    this.toast_control = false;
    this.no_asign = false;
  },
  methods: {
		// 定制商城判断
    tripartite,
    customAccount,
    //  是否特殊账号登录
    account() {
      if ( customAccount('VUE_APP_ACCOUNT') || customAccount('VUE_APP_CHAOWEI')  ) {
        this.customAccounttype = true
      }
    },

    // 关闭电话弹出
    cancel() {
      this.sermob_my = false;
      console.log(this.sermob_my);
    },

    //个人信息
    toMyInfo() {
      if(!customAccount('VUE_APP_CHAOWEI')){
        this.$router.push({
          path: "/personal_info"
        });
      }
    },

    // 获取客服信息
    async getServiceInfo() {
      try {
        const res_mobile = await getServiceConfig("base.server_mobile");
        const res_time = await getServiceConfig("base.server_time");
        if (res_mobile.code === 0) {
          const { data } = res_mobile.data;
          this.service_mobile = data;
        }
        if (res_time.code === 0) {
          const { data } = res_time.data;
          this.service_time = data;
        }
      } catch (e) {}
    },

    // 账户余额
    async getUserAccountList() {
      try {
        let params = {
          page: this.page,
          listRows: this.listRows,
          type: this.current_tab //1全部，2收入，3支出
        };
        const res = await getUserAccountList(params);
        this.is_request = 1;

        if (res.code === 0) {
          const data = res.data;
					
					this.getOrderNumber();
					this.getServiceInfo();
					this.openchated();
					
          // console.log(data);
          this.money = data.money;
          this.list = [...this.list, ...data.list];
          this.total = data.total;
          if (this.list.length === data.total) {
            /* 所有数据加载完毕 */
            this.page++;
            this.$refs.account.$emit("ydui.infinitescroll.loadedDone");
            return;
          }
          /* 单次请求数据完毕 */
          this.page++;
          this.$refs.account.$emit("ydui.infinitescroll.finishLoad");
        }
      } catch (e) {}
    },
    async getOrderNumber() {
      try {
        const res = await getOrderNumber();
        if (res.code === 0) {
          const data = res.data;
          // console.log(data);
          this.$set(this.orderIcon[0], "number", data.pre_pay);
          this.$set(this.orderIcon[1], "number", data.payed);
          this.$set(this.orderIcon[2], "number", data.delivered);
          this.$set(this.orderIcon[3], "number", data.confirmed);
          this.$set(this.orderIcon[4], "number", data.num);
        }
      } catch (e) {}
    },
    orderList(key) {
     // window.location.href = `${baseUrl}/#/order_list?key=${key}`;
      this.$router.push({
        path: '/order_list',
        query: {
          key: key
        }
      })
    },

    async openchated() {
			try {
        let res = await ServicePhone();
        // console.log(res.data.service_mobile);

        if (res.code == 0) {
          this.ServiceStatus = res.data.service_status; //开启
          this.ServiceMobile = res.data.service_mobile; //关闭
        }
      } catch (err) {
        throw err;
      }
    },
    //客服聊天
    async openChat() {
      console.log(this.ServiceStatus)
      //判断后台是否开启客服链接
      if (this.ServiceStatus == 1) {
        var isSdkReady = false;
        ysf("onready", function() {
          isSdkReady = true;
        });
        // 判断 点击客服跳转到客服中心
        // if (isSdkReady) {
        //   location.href = ysf("url");
        // }
        let self = this;
        let uid,mobile,name
        if(localStorage.getItem("userInfo")){
           uid=JSON.parse(localStorage.getItem("userInfo")).id
           mobile=JSON.parse(localStorage.getItem("userInfo")).mobile
           name = JSON.parse(localStorage.getItem("userInfo")).username 
        }else{
          uid = `${Math.random().toString(36).substr(2).slice(0, 4)}${Math.random().toString(36).substr(2).slice(0, 4)}`
        }
        ysf("config", {
          uid: uid,
          mobile: mobile || '商城',
          name: name || '商城',
          unconfirm: 1,
          title: localStorage.getItem('getShareTitle'),
          // bid:JSON.stringify(JSON.parse(localStorage.getItem('businessInfo')).id),
          url: window.location.href,
          success: function() {
            // 成功回调
           if (isSdkReady) {
                  ysf("open");
                  location.href = ysf("url");
                }
          },
          error: function() {
            // 错误回调
            // handle error
          }
        });
        // ysf("product", {
        //   show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
        //   title: self.detail.item_name || "壹企通",
        //   desc: self.detail.item_desc || "壹企通",
        //   picture: self.detail.base_pic || "壹企通",
        //   note: "壹企通",
        //   url: window.location.href,
        //   success: function() {
        //     // 成功回调
        //     if (isSdkReady) {
        //       ysf("open");
        //     }
        //   },
        //   error: function() {
        //     // 错误回调
        //     // handle error
        //   }
        // });
      } else {
        this.sermob_my=true
        // console.log(this.service_mobile);
      }
    },

    shareProduct() {
      ysf("product", {
        show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
        title: "标题",
        desc: "商品描述",
        picture: "商品图片地址",
        note: "备注",
        url: "跳转链接",
        success: function() {
          // 成功回调
          ysf("open");
        },
        error: function() {
          // 错误回调
          // handle error
        }
      });
    },
		
		//是否工行商城
		isIcbc() {
			if (process.env.VUE_APP_BIDS) {
				let bids = process.env.VUE_APP_BIDS.split(',');
				bids.forEach( val => {
					if (val == JSON.parse(getStore('businessInfo')).id) {
						this.icbcStatus = true;
						return;
					}
				});
			}
		},
		
		//饭卡商城显隐
		isFk(){
			if (process.env.VUE_APP_FK) {
				let bids = process.env.VUE_APP_FK.split(',');
				// console.log(process.env.VUE_APP_FK,'process.env.VUE_APP_FK')
				bids.forEach( val => {
					if (val == JSON.parse(getStore('businessInfo')).id) {
							this.fk_show = false;
							return;
					}
				});
			}
		},
		
    // 中行显隐控制
    isZh(){
      if (process.env.VUE_APP_ZHONGHANG) {
				let bids = JSON.parse(process.env.VUE_APP_ZHONGHANG);
				bids.forEach( val => {
					if (val == JSON.parse(getStore('businessInfo')).id) {
							this.zgyh_show = false;
							return;
					}
				});
			}
    },

    // 江阴控制显隐
    isJy(){
      if (process.env.VUE_APP_JY) {
				let bids = JSON.parse(process.env.VUE_APP_JY);
				bids.forEach( val => {
					if (val == JSON.parse(getStore('businessInfo')).id) {
						this.jy_show = false;
						return;
					}
				});
			}
    },

		// 是否昆山农商行商城
		kunshanShop() {
			if (tripartite('VUE_APP_KUNSHAN')) {
				this.isKunshan = true;
			}
		},

		// 是否大地保险签到
		daDiShop() {
			if (tripartite('VUE_APP_DADIBX')) {
				this.isDaDiSign = true;
			}
		},
      
		//  签到弹窗
		signUpDialog() {
			if (this.no_asign) {
				this.directSign()
				return
			}
			let last_sign = []
			if (this.use_info.first_sign_date) {
				last_sign = this.use_info.last_sign_date.split('-')
			}
			let time  = new Date()
			let day = time.getDate()
			if (this.use_info.first_sign_date != '' && !this.use_info.is_signed_yesterday && Number(day) - 2 === Number(last_sign[2])) {
				this.toast_open = true
			}
			let year = time.getFullYear()
			let month = '0' + (time.getMonth() + 1)
			let str = year.toString() + month.toString() + (day-1).toString()
			let nowTime = str.split('-').join('')
			let is_older = this.use_info.last_sign_date.split('-').join('')
			// console.log('this.is_signed:', this.is_signed, Number(nowTime) - Number(is_older))
			if (!this.is_signed && (Number(nowTime) - Number(is_older) != 1)) {
				this.directSign()
			}
		},

		closeDialog() {
			this.toast_open = false
			this.no_asign = true
		},
		async confirmBtn() {
			this.no_asign = true
			this.supplementalSign()
		},

		//  当日签到
		async directSign() {
			try {
				const res = await directSign()
				if (!res.code) {
					this.toast_control = true
					this.asign_point = res.data.integral
					this.asign_days = res.data.sign_days
					this.$store.dispatch("changeSignStatus", true)
					this.$store.dispatch('addPoint', res.data.integral)
				} else {
					this.$dialog.toast({
							mes: res.msg,
							timeout: 1500,
							icon: 'error'
					});
				}
			} catch (e) {}
		},

		//  补签
		async supplementalSign() {
			try {
				const res = await supplementalSign()
				if (!res.code) {
					this.toast_open = false
					let time  = new Date()
					let day = time.getDate() - 1
					this.success_txt.push(day)
					let month = time.getMonth() + 1
					if (Number(month) <= 9) {
						month = '0' + month
					}
					this.success_txt.push(month)
					this.success_txt.push(res.data.integral)
					this.dialogClose = true
					setTimeout(()=>{this.dialogClose = false}, 1500)
					this.$store.dispatch('addPoint', res.data.integral)
				} else {
					this.toast_open = false
					this.$dialog.toast({
							mes: res.msg,
							timeout: 1500,
							icon: 'error'
					});
				}
			} catch (e) {}
		},
		
		// 获取用户积分余额
		async getBalance() {
			try {
				const res = await getSystemUserInfo();
				if (res.code === 0) {
					const data = res.data;
					this.balanceInt = data.integral;
					this.getUserAccountList();
				}
			} catch (e) {
				//TODO handle the exception
			}
		}
  },
  created() {
    console.log(process.env['VUE_APP_ZHONGHANG'])
		sessionStorage.route = this.$route.name;
		this.isIcbc();
    this.isFk();
    this.kunshanShop();
    this.daDiShop();
    this.account();
    this.isZh();
    this.isJy();
  }
};
</script>

<style scoped lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";
.p_nickname{
    width: 100%;
    font-size: .26rem;
    padding: .1rem .1rem .2rem .4rem;
    background: #fff;
}
.my-container {

  .sign-up {
      width: 1rem;
      height: 0.48rem;
      font-size: 0.24rem;
      color: #FFFFFF;
      background: var(--main-color);
      position: absolute;
      top: 0.25rem;
      right: 0;
      border-radius: 0.28rem 0px 0px 0.28rem;
      text-align: center;
      line-height: 0.48rem;
      font-weight: lighter;
      z-index: 99;
    }

  // 签到弹窗
  .toast-mask {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    width: 100%;
    height: 100%;
  }
  .toast {
    .wh(4.7rem, 5.4rem);
    background: @color-white;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100;
    // transform: translate(-50%, -50%);
    margin: -2.7rem 0 0 -2.35rem;
    border-radius: 0.2rem;

    .toast-container {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;

      .success {
        width:100%;
        height:0.5rem;
        font-size:0.36rem;
        color:rgba(51,51,51,1);
        line-height:0.5rem;
        margin-top: 0.52rem;
      }

      .success-point {
        width: 100%;
        height:0.34rem;
        font-size:0.24rem;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:17px;
        margin-top: 0.01rem;

        
      }

      .img {
        width:3.24rem;
        height: 2.12rem;
        margin: 0.18rem 0 0.42rem 0;
      }

      .point-amount {
          color: #005B8A;
        }

      .toast-bottom {
        .sc(0.28rem, #FF6600);
        line-height: 0.4rem;
        text-align: center;
        width: 100%;

        .toast-btn {
          .wh(2.2rem, 0.68rem);
          line-height: 0.68rem;
          text-align: center;
          .sc(0.28rem, @color-white);
          background: rgba(254, 85, 46, 1);
          border-radius: 0.34rem;
          margin-top: 0.16rem;

        }
      }

    }
  }

  .reissue-success {
    letter-spacing: 0.02rem;
    .toast-mask {
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 100;
      width: 100%;
      height: 100%;
    }

    .toast {
      .wh(4.68rem, 2.32rem);
      background: @color-white;
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 100;
      margin: -1.62rem 0 0 -2.34rem;
      border-radius: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .toast-containe {
        text-align: center;
        .success-txt {
          font-size: 0.36rem;
          font-weight:bold;
          color: #005B8A;
          margin-bottom: 0.20rem
        }
        .success-poin {
          font-size: 0.24rem;
          font-weight:500;
          color:rgba(51,51,51,1);
        }
      }
    }
  }

  .my-sty {
    font-size: 0.34rem;
    line-height: 0.46rem;
  }
  .presented{
    border-left: 1px solid #f6f6f6;
    height: 1rem;
  }
  .head-box {
    position: relative;
    height: 2.4rem;
    background: @color-white;
    padding: 0.42rem 0.24rem 0.42rem 0.42rem;
    font-size: 0.38rem;
    // margin-bottom: 0.2rem;

    .head-img {
      .wh(1.56rem, 1.56rem);
      border-radius: 50%;

      margin-right: 0.3rem;
    }

    .info-box {
      // height: 1.56rem;
      padding: 0.22rem 0;
      font-weight: 500;
      white-space: nowrap;

      .integral-box {
        .sc(0.24rem, #333);
        background: url("../../assets/icon_detail@3x.png") no-repeat right
          bottom;
        background-size: 0.32rem 0.32rem;
        padding-right: 0.32rem;
        white-space: nowrap;
      }

      .integral-number {
        .sc(0.44rem, #333);
        margin-right: 0.06rem;
        font-weight: bold;
      }
    }

    .detail_box {
      .wh(20%, 100%);

      .detail_icon {
        .wh(0.32rem, 0.32rem);
        .bis("../../assets/icon_detail");
      }
    }
  }

  /*  订单*/

  .order-box {
    height: 2.44rem;
    background: @color-white;
    margin-bottom: 0.2rem;
    margin-top: 0.2rem;

    .order-title {
      height: 0.68rem;
      padding-right: 0.46rem;
      padding-left: 0.24rem;
      background: white url(../../assets/icon_more@3x.png) no-repeat 97% 50%;
      background-size: 0.14rem 0.24rem;

      .left {
        .sc(0.32rem, #2b2b2b);
      }

      .right {
        .sc(0.24rem, #999999);
      }
    }

    .order-type {
      height: 1.76rem;
      padding: 0.44rem 0.3rem 0.24rem 0.38rem;
      .sc(0.28rem, #999999);

      .order-item {
        height: 100%;
        position: relative;

        i {
          .sc(0.48rem, var(--main-color));
        }

        .mark {
          position: absolute;
          background: #ec5b55;
          border-radius: 50%;
          .wh(0.28rem, 0.28rem);
          .sc(0.2rem, @color-white);
          line-height: 0.28rem;
          text-align: center;
          right: 0;
          top: -0.28rem;
        }
      }
    }
  }

  .tab-list {
    background: @color-white;
    margin-bottom: 0.2rem;

    .tab-item {
      .wh(100%, 0.88rem);
      .sc(0.32rem, #333333);
      background: white url(../../assets/icon_detail@3x.png) no-repeat 97% 50%;
      background-size: 0.4rem 0.4rem;

      img {
        .wh(0.48rem, 0.48rem);
        margin: 0 0.2rem 0 0.3rem;
      }

      .right-tab {
        .wh(100%, 100%);
        line-height: 0.88rem;
        span {
          color: #999;
          margin-left: 0.32rem;
        }
      }
      .feedbackStyle {
        margin-left: 0.2rem;
      }
    }
  }
}
// 客服弹窗样式
  .PopUp {
      // background-color: red;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 999999;
      bottom: 0%;
      .bot-mobile {
        width: 80%;
        // border: 1px solid #fff;
        position: absolute;
        height: 3rem;
        bottom: 4rem;
        left: 0.5rem;
        text-align: center;
        line-height: 80px;
        background-color: #fff;
        margin-left: 0.3rem;
        border-radius: 10px;
        img {
          width: 0.5rem;
          height: 0.5rem;
          float: right;
          margin: 0.2rem;
        }
        .service-phone {
          font-size: 19px;
					width: 100%;
					display: flex;
					justify-content: center;
					align-content: center;
					position: absolute;
					margin-top: 0.5rem;
        }
        .service-phone span {
          color: #000;
        }
        .servicepnone {
          display: inline-block;
          // line-height: 4rem;
          width: 2rem;
          height: 0.5rem;
          font-size: 16px;
          color: #000;
          margin-top: 0.25rem;
        }
        .dial {
          left: 0rem;
          width: 1.5rem;
        }
        .dial,
        .cancel {
        position: absolute !important;
    font-size: 15px;
    border-radius: 0 0 0 10px;
    line-height: 0.8rem !important;
    height: 0.8rem;
    width: 3rem;
    // border: 1px solid #ccc;
    line-height: 0.5rem;
    text-align: center;
    color: #fff;
    top: 2.2rem !important;
    background: var(--main-color);
        }
        .cancel {
          left: 50% !important;
          width: 50%;
          border-radius: 0 0  10px 0;
          background:#f1f1f1;
          color: var(--main-color)
        }
      }
    }




// 新增余额、充值
.newly {
  background-color: #fff;
  height: 1.35rem;
  margin-bottom: 0.2rem;
  .newlyDv {
    display: flex;
    border-top: 1px solid #f6f6f6;
    margin: 0rem 0.4rem 0rem 0.4rem;
    .balanceImg {
      height: 50%;
      background: url("../../assets/rechsrge.png") no-repeat center;
      background-size: 24px;
      margin: 0.1rem 0;
    }
    .jifen{
      height: 50%;
      background: url("../../assets/jfen.png") no-repeat center;
      background-size: 24px;
      margin: 0.1rem 0;
    }
    .jifenzs{
      height: 50%;
      background: url("../../assets/jjfenzs.png") no-repeat center;
      background-size: 24px;
      margin: 0.1rem 0;
    }
    .balance {
      display: inline-block;
      flex: 1;
      border-right: 1px solid #f6f6f6;
      height: 1rem;
      text-align: center;
      margin-top: 0.2rem;
      .balanceFigure {
        height: 50%;
        font-size: 0.24rem;
        color: #333333;
      }
    }
    .balanceChaoWei {
      display: inline-block;
      flex: 1;
      // border-right: 1px solid #f6f6f6;
      height: 1rem;
      text-align: center;
      margin-top: 0.2rem;
      .balanceFigure {
        height: 50%;
        font-size: 0.24rem;
        color: #333333;
      }
    }
    .integral {
      display: inline-block;
      flex: 1;
      height: 1rem;
      text-align: center;
      margin-top: 0.2rem;
      color: #333333;
      font-size: 0.24rem;
      .integralImg {
        height: 50%;
        margin: 0.1rem 0;
        background: url("../../assets/conversion.png") no-repeat center;
        background-size: 24px;
      }
      .integralFigure {
        height: 50%;
      }
    }
	.kunshang {
		line-height: 1rem;
		font-size: 0.35rem;
	}
  }
}

</style>
