var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-container" },
    [
      _vm.isDaDiSign
        ? _c(
            "div",
            {
              staticClass: "sign-up",
              on: {
                click: function ($event) {
                  !_vm.is_signed && _vm.signUpDialog()
                },
              },
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.is_signed ? "已签到" : "签到") +
                  "\n\t\t"
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "head-box df fdr alc jc-sb" }, [
        _c("div", { staticClass: "df alc" }, [
          _c("img", {
            staticClass: "head-img",
            attrs: { src: _vm.head_img, alt: "head_img" },
            on: { click: _vm.toMyInfo },
          }),
          _vm.type !== 2
            ? _c(
                "div",
                { staticClass: "my-sty", on: { click: _vm.toMyInfo } },
                [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.nickname) + "\n\t\t\t\t")]
              )
            : _c("div", { staticClass: "info-box df fdc als jc-sb my-sty" }, [
                _c("div", { on: { click: _vm.toMyInfo } }, [
                  _vm._v(_vm._s(_vm.use_info.nickname)),
                ]),
              ]),
        ]),
        !_vm.customAccount("VUE_APP_CHAOWEI")
          ? _c(
              "div",
              {
                staticClass: "detail_box df alc jc-end",
                on: { click: _vm.toMyInfo },
              },
              [_c("div", { staticClass: "detail_icon" })]
            )
          : _vm._e(),
      ]),
      _vm.p_nickname
        ? _c("div", { staticClass: "p_nickname" }, [
            _vm._v("\n\t\t\t推广人：" + _vm._s(_vm.p_nickname) + "\n\t\t"),
          ])
        : _vm._e(),
      _vm.type !== 3 &&
      !_vm.icbcStatus &&
      !_vm.tripartite("VUE_APP_TABEN") &&
      _vm.fk_show &&
      _vm.zgyh_show
        ? _c("div", { staticClass: "newly" }, [
            _vm.isKunshan ||
            _vm.tripartite("VUE_APP_BDT") ||
            _vm.tripartite_type === "VUE_APP_ZJG"
              ? _c("div", { staticClass: "newlyDv" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "integral kunshang" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.type === 1 ? _vm.money : _vm.balanceInt) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                ])
              : _c(
                  "div",
                  { staticClass: "newlyDv" },
                  [
                    _vm.type === 1
                      ? _c(
                          "router-link",
                          { staticClass: "balance", attrs: { to: "account" } },
                          [
                            _c("div", { staticClass: "balanceImg" }),
                            _c("div", { staticClass: "balanceFigure" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t¥" +
                                  _vm._s(
                                    _vm.type === 1 ? _vm.money : _vm.balanceInt
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.isDaDiSign
                      ? _c(
                          "router-link",
                          {
                            staticClass: "balance",
                            attrs: { to: "integral_list_sign" },
                          },
                          [
                            _c("div", { staticClass: "balanceImg" }),
                            _c("div", { staticClass: "balanceFigure" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t¥" +
                                  _vm._s(
                                    _vm.type === 1 ? _vm.money : _vm.balanceInt
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.type === 2 && !_vm.isDaDiSign
                      ? _c(
                          "router-link",
                          {
                            class: !_vm.customAccount("VUE_APP_CHAOWEI")
                              ? "balance"
                              : "balanceChaoWei",
                            attrs: { to: "integral_list" },
                          },
                          [
                            _c("div", {
                              class: _vm.type == 2 ? "jifenzs" : "balanceImg",
                            }),
                            _c("div", { staticClass: "balanceFigure" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.type === 1 ? _vm.money : _vm.balanceInt
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    !_vm.customAccount("VUE_APP_CHAOWEI") && _vm.jy_show
                      ? _c(
                          "router-link",
                          {
                            staticClass: "integral",
                            attrs: { to: "/exchange" },
                          },
                          [
                            _c("div", {
                              class: _vm.type == 2 ? "jifen" : "balanceImg",
                            }),
                            _c("div", { staticClass: "integralFigure" }, [
                              _vm._v("兑换"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    (_vm.type == 1 || _vm.type == 2) &&
                    !_vm.customAccounttype &&
                    _vm.jy_show
                      ? _c(
                          "router-link",
                          {
                            staticClass: "integral presented",
                            attrs: { to: "/presented" },
                          },
                          [
                            _c("div", {
                              class: _vm.type == 2 ? "jifenzs" : "balanceImg",
                            }),
                            _c("div", { staticClass: "integralFigure" }, [
                              _vm._v("赠送"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "order-box" }, [
        _c(
          "div",
          {
            staticClass: "order-title df fdr alc jc-sb",
            on: {
              click: function ($event) {
                return _vm.orderList("ALL")
              },
            },
          },
          [
            _c("div", { staticClass: "left" }, [_vm._v("我的订单")]),
            _c("div", { staticClass: "right" }, [_vm._v("查看全部订单")]),
          ]
        ),
        _c(
          "div",
          { staticClass: "order-type df fdr alc jc-sb" },
          _vm._l(_vm.orderIcon, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "order-item df fdc alc jc-sb",
                on: {
                  click: function ($event) {
                    return _vm.orderList(item.key)
                  },
                },
              },
              [
                _c("icon-svg", {
                  staticClass: "main-color",
                  attrs: { "icon-class": item.icon_class },
                }),
                item.title == "待付款" && _vm.type == 3
                  ? _c("div", [_vm._v("待审批")])
                  : _c("div", [_vm._v(_vm._s(item.title))]),
                item.number > 99
                  ? _c(
                      "div",
                      { staticClass: "mark", staticStyle: { width: "0.4rem" } },
                      [_vm._v("99+")]
                    )
                  : item.number > 0
                  ? _c("div", { staticClass: "mark" }, [
                      _vm._v(_vm._s(item.number)),
                    ])
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "tab-list df fdc alc" },
        [
          _vm.type === 3
            ? _c(
                "router-link",
                {
                  staticClass: "tab-item df fdr alc",
                  attrs: {
                    to: {
                      name: "approval_list",
                      query: { key: _vm.use_info.user_type == 1 ? 3 : 1 },
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/icon_my_caigou@3x.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "right-tab border-bottom-1px" }, [
                    _vm._v("采购审批"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.type === 1 && !_vm.icbcStatus && _vm.fk_show && _vm.zgyh_show
            ? _c(
                "router-link",
                {
                  staticClass: "tab-item df fdr alc",
                  attrs: { to: "account" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/icon_my_zhanghu@3x.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "right-tab border-bottom-1px" }, [
                    _vm._v("我的账户"),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "router-link",
            { staticClass: "tab-item df fdr alc", attrs: { to: "address" } },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/icon_wd_dizhi@3x.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "right-tab border-bottom-1px" }, [
                _vm._v("收货地址"),
              ]),
            ]
          ),
          _c(
            "router-link",
            { staticClass: "tab-item df fdr alc", attrs: { to: "collection" } },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/icon_my_shouchang@3x.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "right-tab border-bottom-1px" }, [
                _vm._v("我的收藏"),
              ]),
            ]
          ),
          _vm.type !== 3 &&
          !_vm.tripartite("VUE_APP_TABEN") &&
          _vm.fk_show &&
          _vm.tripartite_type !== "VUE_APP_ZJG" &&
          !_vm.customAccount("VUE_APP_CHAOWEI") &&
          _vm.zgyh_show &&
          _vm.jy_show
            ? _c(
                "router-link",
                {
                  staticClass: "tab-item df fdr alc",
                  attrs: { to: "goods_reserve" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/static/image/wuzicangku.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "right-tab border-bottom-1px" }, [
                    _vm._v("物资领用"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.type !== 3 &&
          !_vm.tripartite("VUE_APP_TABEN") &&
          _vm.fk_show &&
          _vm.tripartite_type !== "VUE_APP_ZJG" &&
          !_vm.customAccount("VUE_APP_CHAOWEI") &&
          _vm.zgyh_show &&
          _vm.jy_show
            ? _c(
                "router-link",
                {
                  staticClass: "tab-item df fdr alc",
                  attrs: { to: "reserve_details" },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/static/image/jilu.png"), alt: "" },
                  }),
                  _c("div", { staticClass: "right-tab border-bottom-1px" }, [
                    _vm._v("领用记录"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.zgyh_show && _vm.jy_show
            ? _c(
                "router-link",
                {
                  staticClass: "tab-item df fdr alc",
                  attrs: { to: "coupons" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/icon_my_youhuiquan@3x.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "right-tab border-bottom-1px" }, [
                    _vm._v("我的优惠券"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.zgyh_show && _vm.jy_show
            ? _c(
                "router-link",
                {
                  staticClass: "tab-item df fdr alc",
                  attrs: { to: "lucky_wheel" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/icon_my_zhuanpan@3x.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "right-tab" }, [
                    _vm._v("幸运大转盘"),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-list df fdc alc" },
        [
          _vm.ServiceStatus == 1
            ? _c(
                "div",
                {
                  staticClass: "tab-item df fdr alc",
                  on: { click: _vm.openChat },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icon_shangpin_kefu@2x.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "right-tab border-bottom-1px" }, [
                    _vm._v("客服中心"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.ServiceStatus == 0
            ? _c(
                "a",
                {
                  staticClass: "tab-item df fdr alc",
                  on: { click: _vm.openChat },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/icon_my_kefu@3x.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "right-tab border-bottom-1px" }, [
                    _vm._v("\n          客服中心\n        "),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.sermob_my == true
            ? _c("div", { staticClass: "PopUp" }, [
                _c(
                  "div",
                  { staticClass: "bot-mobile", on: { click: _vm.openChat } },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/ic_back_.png") },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.cancel.apply(null, arguments)
                        },
                      },
                    }),
                    _c("span", { staticClass: "service-phone" }, [
                      _vm._v("\n            客服电话:\n            "),
                      _c("span", [_vm._v(_vm._s(_vm.ServiceMobile))]),
                    ]),
                    _c("span", { staticClass: "dial" }, [
                      _c("a", { attrs: { href: "tel:" + _vm.ServiceMobile } }, [
                        _vm._v("确认呼叫"),
                      ]),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "cancel",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.cancel.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "router-link",
            { staticClass: "tab-item df fdr alc", attrs: { to: "feedback" } },
            [
              _c("yd-icon", {
                staticStyle: { "padding-left": "0.3rem" },
                attrs: { name: "feedback", size: "0.48rem", color: "#666" },
              }),
              _c(
                "div",
                { staticClass: "right-tab feedbackStyle border-bottom-1px" },
                [_vm._v("意见反馈")]
              ),
            ],
            1
          ),
          !_vm.icbcStatus && _vm.tripartite_type !== "VUE_APP_ZJG"
            ? _c(
                "router-link",
                {
                  staticClass: "tab-item df fdr alc",
                  attrs: { to: "setting" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/icon_my_shezhi@3x.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "right-tab" }, [_vm._v("设置")]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.sermob_my
        ? _c("div", { staticStyle: { height: "1rem" } })
        : _vm._e(),
      _c("foot-nav"),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.toast_control,
            expression: "toast_control",
          },
        ],
        staticClass: "toast-mask",
        on: {
          click: function ($event) {
            _vm.toast_control = false
          },
        },
      }),
      _vm.toast_control
        ? _c("div", { staticClass: "toast" }, [
            _c("div", { staticClass: "toast-container df fdc alc" }, [
              _c("p", { staticClass: "success" }, [_vm._v("恭喜签到成功")]),
              _c("p", { staticClass: "success-point" }, [
                _vm._v("\n\t\t\t\t\t获得\n\t\t\t\t\t"),
                _c("span", { staticClass: "point-amount" }, [
                  _vm._v(_vm._s(_vm.asign_point)),
                ]),
                _vm._v("\n\t\t\t\t\t积分！\n\t\t\t\t"),
              ]),
              _vm._m(1),
              _c("p", [
                _vm._v("\n\t\t\t\t\t您已连续签到\n\t\t\t\t\t"),
                _c("span", { staticClass: "point-amount" }, [
                  _vm._v(_vm._s(_vm.asign_days)),
                ]),
                _vm._v("\n\t\t\t\t\t天\n\t\t\t\t"),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("dialog-alert", {
        attrs: {
          textMyPage: "是否补签并完成连续签到？",
          textContent: "昨日未签到！",
          toastControl: _vm.toast_open,
        },
        on: { confirmBtn: _vm.confirmBtn, closeDialog: _vm.closeDialog },
      }),
      _c("div", { staticClass: "reissue-success" }, [
        _vm.dialogClose ? _c("div", { staticClass: "toast-mask" }) : _vm._e(),
        _vm.dialogClose
          ? _c("div", { staticClass: "toast" }, [
              _c("div", { staticClass: "toast-containe" }, [
                _c("p", { staticClass: "success-txt" }, [
                  _vm._v("恭喜补卡成功！"),
                ]),
                _c("p", { staticClass: "success-poin" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t补签" +
                      _vm._s(this.success_txt[1]) +
                      "月" +
                      _vm._s(this.success_txt[0]) +
                      "日获得\n\t\t\t\t\t\t"
                  ),
                  _c("span", [_vm._v(_vm._s(this.success_txt[2]))]),
                  _vm._v("积分！\n\t\t\t\t\t"),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "balance" }, [
      _c("div", { staticClass: "balanceImg" }),
      _c("div", { staticClass: "balanceFigure" }, [_vm._v("积分")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img" }, [
      _c("img", { attrs: { src: require("../../assets/sign.png"), alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }