var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog" }, [
    _vm.toastControl
      ? _c("div", { staticClass: "toast-mask", on: { click: _vm.closeDialog } })
      : _vm._e(),
    _vm.toastControl
      ? _c("div", { staticClass: "toast" }, [
          _c("div", { staticClass: "toast-container df fdc" }, [
            _c("div", { staticClass: "dialog-top" }, [
              _vm.textTitle
                ? _c("p", { staticClass: "success" }, [
                    _vm._v(_vm._s(_vm.textTitle)),
                  ])
                : _vm._e(),
              _c("p", { staticClass: "success-point" }, [
                _vm._v(_vm._s(_vm.textContent)),
              ]),
              _vm.textMyPage
                ? _c("p", { staticClass: "myPage" }, [
                    _vm._v(_vm._s(_vm.textMyPage)),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "dialog-btn" }, [
              _c(
                "p",
                {
                  staticClass: "close-btn btn",
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("取消")]
              ),
              _c("div", { staticClass: "road" }),
              _c(
                "p",
                {
                  staticClass: "confirm-btn btn",
                  on: { click: _vm.confirmBtn },
                },
                [_vm._v("确定")]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }