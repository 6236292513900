<template>
  <div class="dialog">
    <!-- 补签弹窗 -->
    <div class="toast-mask" v-if="toastControl" @click="closeDialog"></div>
    <div class="toast" v-if="toastControl">
      <div class="toast-container df fdc">
        <div class="dialog-top">
          <p v-if="textTitle" class="success">{{textTitle}}</p>
          <p class="success-point">{{textContent}}</p>
          <p v-if="textMyPage" class="myPage">{{textMyPage}}</p>
        </div>
        <div class="dialog-btn">
          <p class="close-btn btn" @click="closeDialog">取消</p>
          <div class="road"></div>
          <p class="confirm-btn btn" @click="confirmBtn">确定</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Dialog',
  props: {
    toastControl: {
      type: Boolean,
      default: false
    },
    textTitle: {
      type: String,
      default: ''
    },
    textContent: {
      type: String,
      default: ''
    },
    textMyPage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    confirmBtn() {
      this.$emit('confirmBtn')
    }
  },
  mounted() {
  }
}
</script>

<style lang="less" scoped>

@import "../../common/less/variable.less";
@import "../../common/less/mixin.less"; 

  .dialog {
    .toast-mask {
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 100;
      width: 100%;
      height: 100%;
    }

    .toast {
      .wh(4.68rem, 3.08rem);
      background: @color-white;
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 100;
      transform: translate(-50%, -50%);
      border-radius: 0.2rem;

      .toast-container {
        width:  4.68rem;
          height: 2.04rem;
        .dialog-top {
          width:  4.68rem;
          height: 2.04rem;
          border-bottom: 0.02rem solid #F5F5F5; 
          letter-spacing: 0.02rem;
           .success {
            width: 100%;
            height: 0.4rem;
            font-size: 0.28rem;
            font-weight:500;
            color:rgba(51,51,51,1);
            line-height: 0.4rem;
            margin: 0.34rem 0 0 0.4rem;
          }

          .myPage {
            text-align: center;
            color:rgba(51,51,51,1);
            font-size: 0.28rem;
            font-weight:500;
            padding: 0.2rem 0 0.7rem 0;
          }

           .success-point {
            color: #005B8A;
            font-size: 0.4rem;
            text-align: center;
            padding: 0.5rem 0 0.3rem 0;
          }
        }

        .dialog-btn {
          height: 1.04rem;
          position: relative;
          letter-spacing: 0.02rem;

          .btn {
            width: 2.335rem;
            height: 1.04rem;
            float: left;
            text-align: center;
            line-height: 1.04rem;
          }
          .close-btn {
            font-size:0.24rem;
            color:rgba(172,172,172,1)
          }
          .confirm-btn {
            color:#005B8A;
            font-size:0.28rem;
          }
          .road {
            float: left;
            width: 0.01rem;
            height: 0.42rem;
            border: 0.01rem solid #F5F5F5;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -0.005rem;
            margin-top: -0.21rem;
          }
        }
      }
    }
  }
</style>